<template>
  <v-data-table :headers="headers" :items="taxNumbers" dense :search="search">
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-col cols="12" sm="12" md="8" lg="8">
            <v-text-field
              flat
              dense
              outlined
              background-color="white"
              v-model="search"
              placeholder="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="success"
                  v-on="on"
                  dark
                  rounded
                  :to="{ name: 'Create Tax Number' }"
                  class="mr-2"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Create</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  v-on="on"
                  dark
                  rounded
                  :to="{ name: 'Skip Tax Number' }"
                  class="mr-2"
                >
                  <v-icon>
                    mdi-skip-next
                  </v-icon>
                </v-btn>
              </template>
              <span>Skip</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" dark rounded :to="{ name: 'Change Tax Number' }">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Change</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ taxNumbers.map(x => x).indexOf(item) + 1 }}
    </template>
    <template v-slot:item.used="{ item }">
      <v-chip small :color="getColor(item.used)" dark>
        {{ item.used ? "Yes" : "No" }}
      </v-chip>
    </template>
    <template v-slot:item.skip="{ item }">
      <v-chip small :color="getColor(item.skip)" dark>
        {{ item.skip ? "Yes" : "No" }}
      </v-chip>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "tax-number",
  data: () => ({
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Serial Number",
        value: "serialNumber",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Used",
        value: "used",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Skip",
        value: "skip",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    search: "",
  }),

  computed: {
    ...mapState("taxNumber", ["taxNumbers"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("taxNumber/getAll")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    getColor(used) {
      if (used) return "green";
      else return "red";
    },
  },
};
</script>

<style></style>
